<template>
  <div>
    <md-button @click="showDialog = true">Crear nuevo</md-button>
    <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Nueva aplicación</md-dialog-title>
    <div class="md-layout md-alignment-center margin-fix">
      <p>Introduce datos correspondientes para la aplicación</p>
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Código de aplicación</label>
          <md-input v-model="$v.application.code.$model" />
        </md-field>
        <div v-if="$v.application.code.$dirty">
          <span class="md-helper-text" v-if="!$v.application.code.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.application.code.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.application.code.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Descripción</label>
          <md-input v-model="$v.application.description.$model" />
        </md-field>
        <div v-if="$v.application.description.$dirty">
          <span class="md-helper-text" v-if="!$v.application.description.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.application.description.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.application.description.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <div v-if="category.length < 1">
          <label>Categoría</label>
          <Missing />
        </div>
        <md-field v-else>
          <label>Categoría</label>
          <md-select v-model="$v.application.category_id.$model">
            <md-option v-for="cat in category" :value="cat.id">{{cat.category}}</md-option>
          </md-select>
        </md-field>
        <div v-if="$v.application.category_id.$dirty">
          <span class="md-helper-text" v-if="!$v.application.category_id.required">Campo requerido</span>
        </div>
      </div>
    </div>
    <md-dialog-actions class="margin-fix">
      <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
      <md-button class="md-primary" @click="onConfirm(application)">Crear</md-button>
    </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import Missing from '@/components/Common/Missing.vue'
import validations from '@/validations/application'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Create',
  components: {
    Missing
  },
  data() {
    return {
      showDialog: false,
      application: {
        code: '',
        description: '',
        category_id: '',
      },
    }
  },
  validations: validations,
  mounted() {
    Object.assign(this.$data, this.$options.data.apply(this))
    this.categories()

  },
  computed: {
    ...mapGetters({category: 'categories/listCategories'})
  },
  methods: {
    ...mapActions({categories: 'categories/getApplicationCategories'}),
    onConfirm(application) {
      if (!this.$v.$invalid) {
        this.$store.dispatch('applications/addApplication', application)
        this.showDialog = false
        this.cleanUp()
      }
    },
    cleanUp() {
      this.$data.application = this.$options.data.apply(this).application
      this.$v.$reset()
    }
  }
}
</script>
