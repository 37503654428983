<template>
  <div class="top">
    <md-chip class="md-primary">No hay registros</md-chip>
  </div>
</template>

<script>
export default {
  name: 'Missing'
}
</script>
