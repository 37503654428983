<template>
  <div>
    <md-button class="md-icon-button" @click="showDialog = true">
      <md-icon>build</md-icon>
    </md-button>
    <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Editar aplicación</md-dialog-title>
    <div class="md-layout md-alignment-center margin-fix">
      <p>Introduce datos correspondientes para la aplicación</p>
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Código de aplicación</label>
          <md-input v-model="$v.application.code.$model" />
        </md-field>
        <div v-if="$v.application.code.$dirty">
          <span class="md-helper-text" v-if="!$v.application.code.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.application.code.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.application.code.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Descripción</label>
          <md-input v-model="$v.application.description.$model" />
        </md-field>
        <div v-if="$v.application.description.$dirty">
          <span class="md-helper-text" v-if="!$v.application.description.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.application.description.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.application.description.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Categoría</label>
          <md-select v-model="$v.application.category_id.$model">
            <md-option v-for="cat in category" :value="cat.id">{{cat.category}}</md-option>
          </md-select>
        </md-field>
        <div v-if="$v.application.category_id.$dirty">
          <span class="md-helper-text" v-if="!$v.application.category_id.required">Campo requerido</span>
        </div>
      </div>
    </div>
    <md-dialog-actions class="margin-fix">
      <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
      <md-button class="md-primary" @click="onConfirm(application)">Editar</md-button>
    </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import validations from '@/validations/application'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Edit',
  props: ['data'],
  data() {
    return {
      application: this.$props.data,
      showDialog: false
    }
  },
  validations: validations,
  mounted() {
    this.categories()
    this.brands()
  },
  computed: {
    ...mapGetters({category: 'categories/listCategories', brand: 'brands/listBrands'})
  },
  methods: {
    ...mapActions({categories: 'categories/getApplicationCategories', brands: 'brands/getBrands'}),
    onConfirm(application) {
      if (!this.$v.$invalid) {
        this.$store.dispatch('applications/editApplication', application)
        this.showDialog = false
      }
    },
    onCancel() {
      this.showDialog = false
    }
  }
}
</script>
