<template>
  <div>
    <Loader v-if="loader" />
    <md-table v-model="searched" md-sort="code" md-sort-order="asc" v-else>
      <md-table-toolbar>
        <div class="md-list-item-text space-down">
          <span class="md-display-1 title-fix">Aplicaciones</span>
          <span class="md-caption">Listado de aplicaciones disponibles</span>
          <div class="md-layout md-alignment-left margin-fix">
            <div class="md-layout-item"></div>
            <div class="md-layout-item md-size-80">
              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Busca por código de aplicación" v-model="search" @input="searchOnTable" />
              </md-field>
            </div>
          </div>
        </div>
        <Create />
      </md-table-toolbar>
      <md-table-row>
        <md-table-head md-numeric>ID</md-table-head>
        <md-table-head>Código</md-table-head>
        <md-table-head>Aplicación</md-table-head>
        <md-table-head>Editar</md-table-head>
        <md-table-head>Eliminar</md-table-head>
      </md-table-row>
      <md-table-row v-if="list.length < 1">
        <Empty />
      </md-table-row>
      <md-table-row v-for="item in filteredItems" v-else slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-numeric>{{item.id}}</md-table-cell>
        <md-table-cell md-sort-by="code">
          <router-link :to="{name: 'ShowApplication', params: {id: item.id}}">{{item.code}}</router-link>
        </md-table-cell>
        <md-table-cell>
          <router-link :to="{name: 'ShowApplication', params: {id: item.id}}">{{item.description}}</router-link>
        </md-table-cell>
        <md-table-cell>
          <Edit :data="item" />
        </md-table-cell>
        <md-table-cell>
          <md-button class="md-icon-button md-primary" @click="deleteApplication(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import Create from '@/components/Applications/Create'
import Loader from '@/components/Common/Loader.vue'
import Empty from '@/components/Common/Empty.vue'
import Edit from '@/components/Applications/Edit'
import filter from '@/mixins/filter'
import { mapActions } from 'vuex'
export default {
  name: 'Application',
  mixins: [filter],
  components: {
    Edit, Create, Loader, Empty
  },
  data() {
    return {
      loader: true
    }
  },
  created() {
    this.applications().then(() => {
      this.loader = false
    })
  },
  computed: {
    list: {
      get() {
        return this.$store.getters['applications/listApplications']
      },
      set(value) {
        this.searched = value
      }
    },
    filteredItems() {
      if(this.search.length > 0) {
        return this.searchByName(this.list, this.search, 'code')
      }
      return this.list
    }
  },
  methods: {
    ...mapActions({applications: 'applications/getApplications', deleteApplication: 'applications/deleteApplication'}),
  }
}
</script>
