<template>
  <div>
    <List class="margin-fix"/>
  </div>
</template>
<script>
import List from '@/components/Applications/List.vue'
import auth from '@/mixins/auth'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Applications',
  mixins: [auth],
  components: {
    List
  },
  mounted() {
    this.applications()
  },
  computed: {
    ...mapGetters({list: 'applications/listApplications'})
  },
  methods: {
    ...mapActions({applications: 'applications/getApplications'}),
  }
}
</script>
